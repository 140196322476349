










































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Section } from '@/models/layout'
import { binderModule } from '@/store/store-accessor'
import { InfoIcon } from 'vue-feather-icons'
import { isInvisible } from '@/helpers/layout'
import { scrollToSection } from '../../helpers/scroll'
import SideNavigationLayout from '@/layouts/side-navigation-layout.vue'
import BusinessInfoToggle from '@/components/the-side-navigation/business-info-toggle.vue'
import EventBus from '@/event-bus'

@Component({
  name: 'TheSideNavigation',
  components: ({
    SideNavigationLayout,
    InfoIcon,
    BusinessInfoToggle
  })
})
export default class TheSideNavigation extends Vue {
  @Prop() layout!: Section[]
  @Prop() depth!: number
  @Prop({ default: true }) showProgressBar!: boolean
  @Prop({ default: false }) isReview!: boolean
  reviewCurrentSection = ''
  get activeSectionHeaderId (): string {
    let activeHeader = ''
    for (const section of this.layout) {
      for (const item of section.items as Section[]) {
        if (this.activeSection === item.sectionId) {
          activeHeader = section.sectionId
        }
      }
    }

    return activeHeader
  }

  get visibleSections () {
    return binderModule.visibleSections
  }

  get activeSection () {
    return binderModule.visibleSections[binderModule.visibleSections.length - 1]
  }

  get progress () {
    return binderModule.visibleSections.length / (binderModule.totalSections + 1)
  }

  get progressBarStyle () {
    return {
      width: '1.35rem',
      height: `${this.progress * 200}px`,
      content: '',
      transition: 'all 0.4s'
    }
  }

  get captionPlacement () {
    return {
      top: `${(this.progress * 200) - 18}px`,
      transition: 'all 0.4s'
    }
  }

  get itemsRequiringUpdate (): string[] {
    return binderModule.validationMessages.map(msg => msg.field)
  }

  requiresUpdate (items: Section[]): boolean {
    const questions: string = JSON.stringify(items)
    return this.itemsRequiringUpdate.some(questionID => questions.includes(questionID))
  }

  isVisited (sectionId: string): boolean {
    return this.visibleSections.includes(sectionId)
  }

  filterInvisible (items: Section[]) {
    return items.filter(lt => !lt.sectionId || !isInvisible(lt.sectionId, this.visibleSections))
  }

  navSection (sectionId: string) {
    scrollToSection(sectionId)
  }

  mounted () {
    EventBus.$on('review-highlight-section', (sectionid: string) => {
      this.reviewCurrentSection = sectionid
    })
  }

  isStr (item: any) {
    return typeof item === 'string'
  }
}
