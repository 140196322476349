



























import { XIcon } from 'vue-feather-icons'
import { defineComponent, onMounted, computed } from '@vue/composition-api'
import Drawer from '@/components/drawer/index.vue'
import BusinessInfo from '@/components/business-info/index.vue'
import { binderModule, cobModule } from '@/store/store-accessor'
import { getProductName } from '@/helpers/products'

export default defineComponent({
  name: 'DrawerBusinessInfo',
  components: { BusinessInfo, Drawer, XIcon },
  setup () {
    onMounted(() => {
      cobModule.initializeCOB()
    })

    const toggleBusinessInfo = () => {
      binderModule.setBusinessInfo()
    }

    const showBusinessInfo = computed(() => {
      return binderModule.toggleBusinessInfo
    })

    const answers = computed(() => {
      return binderModule.answers
    })
    const cobs = computed(() => {
      return cobModule.cobs
    })

    const requestedTypesOfInsuranceSlug = computed(() => {
      return answers.value.RequestedTypesOfInsurance as string[] || null
    })

    const insuranceProductName = computed(() => {
      return getProductName(requestedTypesOfInsuranceSlug.value ? requestedTypesOfInsuranceSlug.value[0] : '')
    })
    return { toggleBusinessInfo, showBusinessInfo, answers, cobs, insuranceProductName }
  }
})
