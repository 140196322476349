

















import { Component, Vue } from 'vue-property-decorator'
import { binderModule } from '@/store/store-accessor'
import { UserIcon } from 'vue-feather-icons'

@Component({
  name: 'BusinessInfoToggle',
  components: ({
    UserIcon
  })
})

export default class BusinessInfoToggle extends Vue {
  toggleBusinessInfo () {
    binderModule.setBusinessInfo()
  }

  get businessName () {
    return binderModule.answers.BusinessName as string || null
  }
}
