


















































































































import {
  defineComponent,
  computed,
  ref
} from '@vue/composition-api'
import { binderModule } from '@/store/store-accessor'
import { COB } from '@/models/cob'
import useLoadable from '@/use/useLoadable'
import LoadingIcon from '@/components/loading-icon/index.vue'
import Checkbox from '@/components/controls/checkbox/index.vue'
import { ROUTES } from '@/router/routes'
import { Address, AnswerMap } from '@/models/questionnaire'

export default defineComponent({
  name: 'BusinessInfo',
  components: {
    LoadingIcon,
    Checkbox
  },
  props: {
    answers: {
      type: Object as () => AnswerMap,
      required: true
    },
    cobs: {
      type: Array as () => COB[],
      required: true
    },
    insuranceProductName: {
      type: String,
      required: true
    }
  },
  setup (props, ctx) {
    const { showBtnLoading, btnLoading } = useLoadable()
    const isAcknowledgeChecked = ref(false)
    const router = ctx.root.$router

    const toggleBusinessInfo = () => {
      binderModule.setBusinessInfo()
    }

    const NumberOfLocations = computed(() => {
      return props.answers.NumberOfLocations as number
    })

    const businessInfoData = computed(() => {
      return [
        { testID: 'Company', questionLabel: 'Company', answer: props.answers.BusinessName, type: 'text' },
        { testID: 'Email', questionLabel: 'Email', answer: props.answers.LinkEmail, type: 'text' },
        { testID: 'Insurance', questionLabel: 'Insurance type', answer: props.insuranceProductName, type: 'text' }
      ]
    })

    /**
     * Retrieving location info from the answers object
     * using location number as the location id
     * @param questionId
     */
    const getLocationInfo = (questionId: string) => {
      const locationAddress = props.answers[questionId] as Address
      if (locationAddress) {
        const { street } = locationAddress
        return `${street}`
      }
      return ''
    }

    /**
     * Retrieving class of business answers from the answers object
     * in binder module using location number as the identifier
     * @param locationId
     */
    const getClassOfBusiness = (locationId: number) => {
      // Location1ClassOfBusiness
      const locationCOB = props.answers[`Location${locationId}ClassOfBusiness`] as string
      if (locationCOB) {
        const [code, index] = locationCOB.split('-')
        return props.cobs?.find(cob => cob.code === code) as COB
      }
    }

    const cobSpecification = (locationId: number) => {
      const selectedCOB = getClassOfBusiness(locationId)
      if (selectedCOB) {
        const selectedCOBChild = props.cobs?.find(cobChild =>
          cobChild.ancestorTitles?.includes(selectedCOB.title)) as COB
        return selectedCOBChild || selectedCOB
      }
      return selectedCOB || ''
    }

    const restartApplication = async () => {
      showBtnLoading()
      const preApplicationId = await binderModule.restartApplication()

      toggleBusinessInfo()

      return router.push({
        name: ROUTES.PRE_APPLICATION,
        params: { id: preApplicationId }
      })
    }

    return {
      businessInfoData,
      NumberOfLocations,
      isAcknowledgeChecked,
      getClassOfBusiness,
      getLocationInfo,
      cobSpecification,
      btnLoading,
      restartApplication,
      toggleBusinessInfo
    }
  }
})

